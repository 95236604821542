@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@500;700&display=swap');
@import "../../global.scss";
*{
    box-sizing: border-box;
    text-decoration: none;
}
/*Navbar Styling*/
.navbar{
    font-family: 'open-sans', sans-serif;
    overflow: hidden;
    position: fixed;
    width: 100%;
    padding: 18px 0;
    /* background: #FFCD33; */
    font-family: 'Ubuntu', 'sans-serif';
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 997;
    .max-width{
        padding: 0px 40px;
    }

    &.active{
        background-color: $mainColour;
    }
    &.invis{
        opacity: 0;
    }
    .max-width{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo{
        margin-left: -5px;
        a{
            color: #FFCD33;
            font-size: 28px;
            font-weight: 600;
        }
    }
    .menu{
        overflow: hidden;
        li{
            list-style: none;
            display: inline-block;
            font-size: 16px;
            a{
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-left: 25px;
                -webkit-transition: cover 0.3s ease-in-out;
                -moz-transition: cover 0.3s ease-in-out;
                -o-transition: cover 0.3s ease-in-out;
                transition: cover 0.3s ease-in-out;
                &:hover{
                    color: #FFCD33;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
            }            
        }
        .current{
            overflow: hidden;
            a{
                color: #FFCD33;
                font-weight: 500;
                margin-left: 25px;
                -webkit-transition: cover 0.3s ease-in-out;
                -moz-transition: cover 0.3s ease-in-out;
                -o-transition: cover 0.3s ease-in-out;
                transition: cover 0.3s ease-in-out;
                &:hover{
                    color: #FFCD33;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

    }
    .mobile-menu{
        overflow: hidden;
        li{
            list-style: none;
            display: inline-block;
            a{
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                margin-left: 25px;
                -webkit-transition: cover 0.3s ease-in-out;
                -moz-transition: cover 0.3s ease-in-out;
                -o-transition: cover 0.3s ease-in-out;
                transition: cover 0.3s ease-in-out;

                &:hover{
                    color: #FFCD33;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

/* menu button styling */
.menu-icon{
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
}

@media (max-width: 1240px){
    .navbar .mobile-menu{
        overflow: hidden;
        li{
            list-style: none;
            display: inline-block;
            a{
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                margin-left: 25px;
                
                &:hover{
                    color: #FFCD33;
                }
            }
        }
    }
}
@media (max-width: 991px){
    .max-width{
        padding: 0 50px;
    }
    .navbar .mobile-menu{
        li{
            list-style: none;
            display: inline-block;
            a{
                color: #fff;
                font-size: 16px;
                font-weight: 500;
                margin-left: 25px;
                &:hover{
                    color: #FFCD33;
                }
            }
        }
    }
}
@media (max-width: 947px){
    .max-width{
        padding: 0 50px;
    }
    .menu-icon{
        display: block;
        z-index: 999;
        color: #fff;
        font-size: 23px;
        cursor: pointer;
    }
    .logo{
        z-index: 999;
        a{
            color: #fff;
            font-size: 35px;
            font-weight: 600;
        }
    }
    .navbar {
        position: fixed;
        width: 100%;
        // padding: 20px 0;
        /* background: #FFCD33; */
        font-family: 'Ubuntu', 'sans-serif';
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        .menu{
            padding: 0;
            position: fixed;
            height: 100vh;
            min-height: 1000px;
            width: 100%;
            left: -100%;
            top: 0;
            background: $mainColour;
            text-align: center;
            padding-top: 80px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            li{
                display: block;
                a{
                    color: white;
                    display: inline-block;
                    margin: 25px 0;
                    font-size: 25px;
                    padding: 0;
                }
            }
        }
        .mobile-menu{
            padding: 0;
            z-index: 998;
            position: fixed;
            height: 100vh;
            min-height: 1000px;
            width: 100%;
            left: 0;
            top: 0;

            background: $mainColour;
            text-align: center;
            padding-top: 80px;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            li{
                display: block;
                a{
                    color: white;
                    display: inline-block;
                    margin: 25px 0;
                    font-size: 25px;
    
                    &:hover{
                        color: #FFCD33;
                    }
                }
            }
            .current{
                padding: 0;
                display: block;
                a{
                    padding: 0;
                    color: #FFCD33;
                    display: inline-block;
                    margin: 25px 0;
                    font-size: 25px;
                    &:hover{
                        color: #FFCD33;
                    }
                }
            }
        }
    }
}
