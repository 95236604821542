@import "../../global.scss";

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $mainColour;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
    transition: opacity 0.5s ease-out;
    opacity: 1;
    &.fade-out {
        opacity: 0;
        pointer-events: none;
    }
    
    .loading-content {
      z-index: 2;
    }
  
    .balloon-animation {
      width: 300px;
      height: 'auto';
      background-image: url("../images/balloon.png");
      background-size: cover;
      background-repeat: no-repeat;
      animation: floatUp 4.5s ease-in-out forwards;
    }
  }

  
  @keyframes floatUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(-100vh);
    }
  }
  