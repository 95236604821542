@import "../../global.scss";
.max-width{
    max-width: 1300px;
    padding: 0 50px;
    margin: auto;
    overflow-y: hidden;
}
.experience{
    // background: url("../images/darkbluepatterned.jpg");
    // background-position: absolute;
    min-height: 1000px;
    background-color: rgb(24, 26, 29);
    overflow-y: hidden;
    .header{
        padding-top: 75px;
        padding-bottom: 50px;
        // margin: 20px 70px;
        h3{
            color: $standoutColour;
            font-size: 32px;
        }
        h6{
            padding-top: 10px;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
    }
}
