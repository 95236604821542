@import "../../global.scss";
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,700&display=swap');

.max-width{
    max-width: 1300px;
    padding: 0 50px;
    margin: auto;
}
.about-me{
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 500px;
    background: $mainColour;
    min-height: 400px;
    font-family: 'Open Sans', sans-serif;
    .slider{
        overflow: hidden;
        height: 450px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 0.4s ease-out;
        -webkit-transition: all 0.4s ease-out;
        -moz-transition: all 0.4s ease-out;
        -o-transition: all 0.4s ease-out;
        .container{
            width: 100vw;
            display: flex;
            justify-content: center;

            &::-webkit-scrollbar {
                width: 20px;
              }
              
            &::-webkit-scrollbar-track {
                background: transparent;
              }
              
            &::-webkit-scrollbar-thumb {
                background-color: #d6dee1;
                border-radius: 20px;
                border: 6px solid transparent;
                background-clip: content-box;
              
              
                &:hover {
                background-color: #a8bbbf;
                }
            }
            .max-width{
                .about-content{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    &::-webkit-scrollbar {
                        width: 20px;
                      }
                      
                    &::-webkit-scrollbar-track {
                        background: transparent;
                      }
                      
                    &::-webkit-scrollbar-thumb {
                        background-color: #d6dee1;
                        border-radius: 20px;
                        border: 6px solid transparent;
                        background-clip: content-box;
                      
                      
                        &:hover {
                        background-color: #a8bbbf;
                        }
                    }
                    .left{
                        width: 30%;
                        a{
                            .profile-pic{
                                width: 100%;
                                height: auto;
                            }
                        }
                        .tree{
                            height: 350px;
                        }
                        .badminton{
                            height: 375px;
                        }
                    }
                    .right{
                        // margin-right: 60px;
                        line-height: 1.3;
                        width: 70%;
                        color:rgba(255, 255, 255, 0.9);
                    }
                    #twoText{
                        line-height: 1.3;
                        width: 50%;
                        margin-left: 100px;
                        color:rgba(255, 255, 255, 0.9);
                        ul{
                            li{
                                margin-left: -30px;
                                color:rgba(255, 255, 255, 0.9);
                                font-size: 16px;
                                padding: 5px;
                            }
                        }
                    }
                    #threeText{
                        line-height: 1.3;
                        width: 55%;
                        margin-left: 80px;
                        color:rgba(255, 255, 255, 0.9);
                        ul{
                            
                            overflow-x:visible;
                            li{
                                margin-left: -40px;
                                color:rgba(255, 255, 255, 0.9);
                                font-size: 16px;
                                overflow: hidden;
                                padding: 5px;
                            }
                        }
                    }
                }
            }
            
        }
    }
        .container-dots {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            z-index: 99;
            cursor: pointer;
            .dot {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #f1f1f1;
                margin: 0 5px;
                background: #f1f1f1;
                &:hover{
                    background: $standoutColour;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
            }
            .dot.active {
                background: $standoutColour;
            }
            #arrows{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #f1f1f1;
                margin: 0 15px;
                color: #f1f1f1;
                &:hover{
                    color: $standoutColour;
                    background: none;
                }
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
            }
            #arrows-off{
                width: 20px;
                height: 20px;
                margin: 0 15px;
            }
        }
}

@media (max-width: 1076px){
    .about-me {
        height: 700px;

        .slider{
            height: 650px;
            .container{
                margin-bottom: 75px;
                .max-width{
                    .about-content{
                        padding-top: 10px;
                        // height: 1000px;
                        .column{
                            width: 100%;
                        }
                        .left{
                            display: flex;
                            justify-content: center;
                            margin: 0 auto 0px;
                            padding-top: 0;
                            .tree{
                                margin-left: 0;
                                height: 300px;
                            }
                        }
                        .right{
                            padding-top: 35px;
                            flex: 100%;
                        }
                        #twoText{
                            flex: 100%;
                            padding-bottom: 35px;
                            margin-left: 0;
                        }
                        #threeText{
                            h2{
                                text-align: center;
                            }
                            flex: 100%;
                            padding-bottom: 35px;
                            margin-left: 0;
                            ul{
                                margin-left: 20px;
                                li{
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}