@import "../../global.scss";
footer{
    background-color: $mainColour;
    padding-top: 24px;
    padding-bottom: 30px;
    color: #191e29;
    font-size: 14px;
    text-align: center;
    position: relative;
    .social-links {
        margin: 30px 45px 10px 0;
        padding: 0;
        font-size: 30px;
        color: white;
        li{
            display: inline-block;
            margin: 0;
            padding: 0;
            margin-left: 42px;
            color: white;
        }
    }
}