@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@500;700&display=swap');
.intro{
    height: 100vh;
    overflow-y: hidden;
    font-family: 'Poppins', sans-serif;
    // background-color: rgb(24, 26, 29);
    .background-video {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
        z-index: 0;
      
        &::-webkit-media-controls-start-playback-button { //doesn't work lol
          display: none !important;
          -webkit-appearance: none;
        }
      }
    .max-width{
        padding: 0px 40px;
    }
    .home{
            .home-content{
                position: relative;
                margin: 30vh 0vw;
                z-index: 2;
                // position: fixed;
                // font-family: 'Poppins', sans-serif;
                .text-1{
                    color: #fff;
                    font-size: 25px;
                }
                .text-2{
                    color: #fff;
                    padding-top: 10px;
                    font-size: 75px;
                    font-weight: 600;
                    margin-left: -3px;
                }
                .text-3{
                    color: #fff;
                    font-size: 20px;
                    line-height: 1.4;
                }
                .home-icon-div{
                    position: relative;
                    // margin-top: -35px;
                    // margin-left: 120px;
                    margin-left: -40px;
                    align-items: center;
                    display: flex;
                    .learn{
                        text-decoration: none;
                        display: inline-block;
                        background: #FFCD33;
                        font-weight: 500;
                        color: #04132c;
                        font-size: 15px;
                        padding: 8px 16px;
                        margin-top: 16px;
                        border-radius: 15px;
                        border: 2px solid #ffc61c;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        &:hover{
                            // color: #FFCD33;
                            background-color: #ffde7a;
                        }
                    }
                    #home-icons{
                        margin-top: 35px;
                        padding-left: 15px;
                        z-index: 2;
                        color:white;
                        font-size: 25px;
                        padding-bottom: 20px;
                    }

                }            
                
            }
        
        
    }
}
@media (max-width: 1240px){
    .home{
        .max-width{
            margin-left: 0px;
        }
    } 
}
@media (max-width: 991px){
    .max-width{
        padding: 0 30px;
    }
}
@media (max-width: 947px){
    .intro{
        .home{
            .home-content{
                .text-1{
                    font-size: 21px;
                }
                .text-2{
                    font-size: 55px;
                }
                .text-3{
                    font-size: 16px;
                }
                .home-icon-div{
                    margin-top: -2px;
                    .learn{
                        font-size: 13px;
                        padding: 7x 13px;
                        margin-top: 13px;
                        border-radius: 17px;
                    }
                    #home-icons{
                        padding-left: 15px;
                        z-index: 2;
                        color:white;
                        font-size: 20px;
                        padding-bottom: 20px;
                    }
                }          
            }
        }
    }
}
    

@media (max-width: 690px){
    .intro{
        position: relative;
        width: 100%;
        min-height: 500px;
        .home{
            .max-width{
                padding: 0 23px;
            }
            .home-content{
                .text-1{
                    font-size: 18px;
                }
                .text-2{
                    font-size: 45px;
                }
                .text-3{
                    font-size: 13px;
                }
                .home-icon-div{
                    position: relative;
                    margin-top: -5px;
                    // margin-left: 50px;
                    align-items: center;
                    display: flex;
                    .learn{
                        font-size: 10px;
                        padding: 6px 12px;
                    }
                    #home-icons{
                        padding-left: 15px;
                        z-index: 2;
                        color:white;
                        font-size: 15px;
                        padding-bottom: 20px;
                    }

                }    
            }
        }        
    }
}

@media (max-width: 500px){
    .home{
        .home-content{
            .text-1{
                font-size: 15px;
            }
            .text-2{
                font-size: 35px;
            }
            .text-3{
                font-size: 12px;
            }
        }
    }
}
