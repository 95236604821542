@import "../../global.scss";
.max-width{
    max-width: 1300px;
    padding: 0 50px;
    margin: auto;
    overflow-y: hidden;
}
.projects{
    padding-top: 50px;
    min-height: 1000px;
    max-width: 500;
    background-color: rgb(24, 26, 29);
    overflow-y: hidden;
    .header{
        padding-top: 25px;
        padding-bottom: 50px;
        h3{
            color: $standoutColour;
            font-size: 32px;
        }
        h6{
            padding-top: 10px;
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .cards{
        justify-content: center;
    }
    .button{
        position: relative;
        left: 50%;
        margin-left: -50px;
        bottom: -60px;
        transition: all 1s ease;

        text-decoration: none;
        border: 0 none;
        display: block;
        width: 100px;
        height: 100px;

        line-height: 100px;
        border-radius: 100%;
        
        &:hover{
            bottom: -50px;
        }
        #arrow{
            font-size: 25px;
            color: #fff;
            padding-left: 39px;
            padding-bottom: 12px;
        }
    }

}


